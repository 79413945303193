@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply h-full;
    @apply w-full;

    background: #111111;
  }

  #root {
    @apply h-full;
  }
  @font-face {
    font-family: 'pretendard';
    src: url('fonts/Pretendard-Thin.subset.woff2') format('woff2');
    font-weight: 100;
  }
  @font-face {
    font-family: 'pretendard';
    src: url('fonts/Pretendard-ExtraLight.subset.woff2') format('woff2');
    font-weight: 200;
  }

  @font-face {
    font-family: 'pretendard';
    src: url('fonts/Pretendard-Light.subset.woff2') format('woff2');
    font-weight: 300;
  }

  @font-face {
    font-family: 'pretendard';
    src: url('fonts/Pretendard-Regular.subset.woff2') format('woff2');
    font-weight: 400;
  }

  @font-face {
    font-family: 'pretendard';
    src: url('fonts/Pretendard-Medium.subset.woff2') format('woff2');
    font-weight: 500;
  }

  @font-face {
    font-family: 'pretendard';
    src: url('fonts/Pretendard-SemiBold.subset.woff2') format('woff2');
    font-weight: 600;
  }

  @font-face {
    font-family: 'pretendard';
    src: url('fonts/Pretendard-Bold.subset.woff2') format('woff2');
    font-weight: 700;
  }

  @font-face {
    font-family: 'pretendard';
    src: url('fonts/Pretendard-ExtraBold.subset.woff2') format('woff2');
    font-weight: 800;
  }

  @font-face {
    font-family: 'pretendard';
    src: url('fonts/Pretendard-Black.subset.woff2') format('woff2');
    font-weight: 900;
  }
}

@layer components {
  .scroll_hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .scroll_hide::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .break-keep {
    word-break: keep-all;
  }

  .page-enter {
    position: absolute;
    opacity: 0.2;
  }

  .page-enter-active {
    opacity: 0.98;
    transition: opacity 600ms;
  }

  .page-exit {
    position: absolute;

    opacity: 0.2;
  }

  .page-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }

  .input_wrapper {
    @apply py-1;
  }

  .header {
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 10;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-shadow-none {
    box-shadow: none;
  }

  .header_menu_li {
    /* float: left; */
    margin-left: 3.5rem;
    cursor: pointer;
    font-weight: 700;
  }
  .mobile_header_menu_li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    margin: 4px 0px 30px 0px;
    font-size: 18px;
  }

  .header_menu_nav {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    padding: 8px 0px 2px 0px;
    position: relative;
  }

  .header_menu_nav:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: #d9d9d9;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .header_menu_nav:hover:after {
    width: 100%;
    left: 0;
  }
  .header_menu_nav_active {
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    padding: 8px 0px 2px 0px;
    position: relative;
  }
  .header_menu_nav_active:after {
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    width: 100%;
    left: 0;
  }

  .footer {
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
    /* -moz-transform: translateY(-100%); */
    background-color: #111111;
    color: #959595;
    display: flex;
    justify-content: center;
  }

  /* @keyframes openModal {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  } */

  /* .hidden {
    display: none;
  } */

  .mobile_nav_animation {
    position: fixed;
    width: 100vw;
    z-index: 4;
    background-color: #111111;
    margin-top: 75px;
    height: 250px; /* AboutUs, Contact 만 있을 때*/
    /* transition: height 4s ease-out; */
  }

  /* .animation_test {
    transition: all 4s ease-out;
  } */

  input:focus {
    outline: none !important;
    border-color: #fff;
  }
  textarea:focus {
    outline: none !important;
    border-color: #fff;
  }

  select option:first-child {
    color: #515151;
  }

  .contact_input {
    @apply h-14;
    @apply w-full;
    @apply text-lg;
    @apply rounded-sm;
    @apply bg-transparent;
    @apply border;
    @apply border-solid;
    @apply border-gray-400;
    @apply py-3;
    @apply px-4;
  }

  .contact_input_error {
    border-color: #f44a4a !important;
  }

  .contact_error {
    visibility: visible !important;
  }

  .contact_textarea {
    width: 100%;
    height: 250px;
    border: 1px solid #515151;
    background: transparent;
    padding: 12px 18px 12px 16px;
    border-radius: 2px;
    outline-color: #fff;
  }
  /* .contact-type_select * {
    box-sizing: border-box; 
  } */

  .html-select {
    color: brown;
    display: none;
  }
  .contact-type_select {
    position: relative;
    width: 100%;
    height: 56px;
    border-radius: 2px;
    border: 1px solid #515151;
    cursor: pointer;
    background: url('../public/images/contact/btn_dropdown.svg') 100% center no-repeat;
    max-height: 500px;
  }
  .contact-type_select-active {
    border: 1px solid #fff !important;
  }
  .contact-type_selected {
    color: white !important;
  }

  .contact-type_select .contact-type_label {
    display: flex;
    align-items: center;
    width: inherit;
    height: inherit;
    border: 0 none;
    outline: 0 none;
    padding-left: 15px;
    cursor: pointer;
    margin-bottom: 6px;
  }

  @keyframes showContactTypeList {
    0% {
      opacity: 0;
      border: 1px solid #515151;
      transform: scaleY(0.3) translate3d(0, -180%, 0);
      visibility: visible;
      background: rgba(38, 38, 38, 0.3);
      /* background: red; */
    }
    100% {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  .contact-type_select .contact-type_ul {
    position: absolute;
    left: 0;
    width: 100%;
    list-style-type: none;
    padding: 4px;
    border-radius: 2px;
    overflow: hidden;
    animation-duration: 0.3s;
    animation-name: showContactTypeList;
    background: #111;
    border: 1px solid #fff;
    color: #fff;
  }

  .contact-type_select .contact-type_li {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .contact-type_select .contact-type_li:hover {
    background: #262626;
  }

  @media (min-width: 768px) {
    .contact-type_select .contact-type_ul {
      background: rgba(38, 38, 38, 1);
    }
    .contact-type_select .contact-type_li:hover {
      background: #515151;
    }
  }
  .contact-privacy_checkBox {
    display: flex;
    align-items: center;
  }

  .contact-privacy_checkBox input[type='checkbox']:checked + label {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
  }

  .contact-privacy_checkBox input[type='checkbox']:checked + label:after {
    opacity: 1;
  }

  .custom_checkbox {
    display: flex;
    width: 26px;
    height: 26px;
    margin-right: 10px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    border-radius: 4px;
  }
  /* label:after {
    content: url('../public/images/contact/checkMark.svg');
    opacity: 0;
    text-align: center;
    margin-top: 1px;
    margin-left: -0.5px;
    width: 100%;
  } */

  .custom_checkbox_mobile {
    display: flex;

    min-width: 22px;
    min-height: 22px;
    margin-right: 10px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    border-radius: 4px;
    align-self: flex-start;
  }
  label:after {
    content: url('../public/images/contact/checkMark_small.svg');
    opacity: 0;
    text-align: center;
    margin-top: -3px;
    margin-left: -0.5px;
    width: 200%;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px #262626 inset !important;
    -webkit-text-fill-color: #d9d9d9 !important;
  }

  /* input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  } */
}
